// Example api configuration file
const BASE_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5000';

export const endpoints = {
    auth: {
        check: `${BASE_URL}/api/check-auth`,
        login: `${BASE_URL}/api/login`,
        logout: `${BASE_URL}/api/logout`,
    },
    opportunities: {
        search: `${BASE_URL}/api/search`,
        get: `${BASE_URL}/api/get_opportunities`,
        save: `${BASE_URL}/save_opportunity`,
        getSaved: `${BASE_URL}/get_saved_opportunities`,
        import: `${BASE_URL}/api/scrape_and_store`,
        setAsideTypes: `${BASE_URL}/api/set-aside-types`,
    },
    folders: {
        create: `${BASE_URL}/api/create_folder`,
        getAll: `${BASE_URL}/api/get_folders`,
        get: `${BASE_URL}/api/folders`,
        getContents: `${BASE_URL}/api/folder_contents`,
        delete: `${BASE_URL}/api/delete_folder`,
        assign: `${BASE_URL}/api/assign_to_folder`,
        getAllContents: `${BASE_URL}/api/folder_contents_all`,
    },
    files: {
        upload: `${BASE_URL}/upload_file`,
        get: (opportunityId) => `${BASE_URL}/get_opportunity_files/${opportunityId}`,
        delete: `${BASE_URL}/delete_file`,
    },
    ai: {
        chat: `${BASE_URL}/api/ai/chat`,
        generateEmail: `${BASE_URL}/api/ai/generate-email`,
        scrape: `${BASE_URL}/api/ai/scrape`,
    },
};

const apiConfig = {
    BASE_URL,
    // other API related configurations
};

export default apiConfig; 
import React, { useState } from 'react';
import { endpoints } from '../../config/api';

function ImportModal({ isOpen, onClose, folders }) {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [importedOpportunity, setImportedOpportunity] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [isUpdateMode, setIsUpdateMode] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!url.startsWith('https://sam.gov/')) {
      setError('Please enter a valid SAM.gov URL');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(false);
    setImportedOpportunity(null);

    try {
      if (isUpdateMode) {
        const response = await fetch('/api/check_update', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ url }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Failed to check for updates');
        }

        setSuccess(true);
        setUrl('');
        if (data.hasUpdate) {
          setError(`Update detected! ${data.updateMessage}`);
        } else {
          setError('No updates found for this opportunity.');
        }
      } else {
        const response = await fetch(endpoints.opportunities.import, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ url }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Failed to import opportunity');
        }

        setSuccess(true);
        setUrl('');
        setImportedOpportunity(data.opportunity);
      }
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'Failed to process request. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleMoveToFolder = async () => {
    if (!selectedFolder || !importedOpportunity) return;

    try {
      const response = await fetch('/api/assign_to_folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          opportunityId: importedOpportunity.id,
          folderId: selectedFolder
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to move opportunity to folder');
      }

      onClose();

    } catch (err) {
      console.error('Error moving to folder:', err);
      setError(err.message || 'Failed to move opportunity to folder');
    }
  };

  const handleSkip = () => {
    setTimeout(() => {
      onClose();
    }, 500);
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-8 border w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 shadow-2xl rounded-xl bg-white">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 transform -skew-y-2 rounded-xl"></div>
        
        <div className="relative">
          <div className="flex justify-between items-center mb-8">
            <h3 className="text-3xl font-bold text-blue-800">
              {isUpdateMode ? 'Check for Updates' : 'Import Opportunity'}
            </h3>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-600">Update Check Mode</span>
                <button
                  onClick={() => setIsUpdateMode(!isUpdateMode)}
                  className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
                    isUpdateMode ? 'bg-blue-600' : 'bg-gray-200'
                  }`}
                >
                  <span
                    className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                      isUpdateMode ? 'translate-x-6' : 'translate-x-1'
                    }`}
                  />
                </button>
              </div>
              <button 
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </div>

          {!success || isUpdateMode ? (
            <>
              <p className="text-gray-600 mb-6">
                {isUpdateMode 
                  ? 'Enter the URL of a SAM.gov opportunity to check for updates.'
                  : 'Enter the URL of a SAM.gov opportunity to import it into your dashboard.'}
              </p>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="relative">
                  <label htmlFor="url" className="block text-sm font-medium text-gray-700 mb-2">
                    Opportunity URL
                  </label>
                  <div className="flex items-center bg-gray-100 rounded-xl overflow-hidden shadow-inner">
                    <input
                      type="url"
                      id="url"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      placeholder="https://sam.gov/opportunity/..."
                      className="flex-grow py-4 px-6 text-gray-700 bg-transparent focus:outline-none focus:ring-2 focus:ring-blue-300 rounded-xl"
                      required
                    />
                  </div>
                </div>

                {error && (
                  <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded-xl">
                    {error}
                  </div>
                )}

                <div className="flex items-center space-x-4">
                  <button
                    type="submit"
                    disabled={loading}
                    className={`flex-grow bg-blue-500 text-white rounded-xl px-8 py-4 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition duration-150 ease-in-out flex items-center justify-center group ${
                      loading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="20" 
                      height="20" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      className="mr-2 group-hover:transform group-hover:-translate-y-1 transition-transform"
                    >
                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
                      <polyline points="17 8 12 3 7 8"/>
                      <line x1="12" y1="3" x2="12" y2="15"/>
                    </svg>
                    {loading 
                      ? (isUpdateMode ? 'Checking...' : 'Importing...') 
                      : (isUpdateMode ? 'Check for Updates' : 'Import Opportunity')}
                  </button>
                </div>
              </form>
            </>
          ) : (
            !isUpdateMode && (
              <div className="space-y-6">
                <div className="p-3 bg-green-100 border border-green-400 text-green-700 rounded-xl mb-4">
                  Successfully imported opportunity!
                </div>
                
                <div className="space-y-4">
                  <h4 className="text-lg font-semibold text-gray-700">
                    Would you like to move this opportunity to a folder?
                  </h4>
                  
                  <select
                    value={selectedFolder}
                    onChange={(e) => setSelectedFolder(e.target.value)}
                    className="w-full p-3 border rounded-xl text-gray-700 focus:ring-2 focus:ring-blue-300 focus:outline-none"
                  >
                    <option value="">Select a folder...</option>
                    {folders?.map(folder => (
                      <option key={folder.id} value={folder.id}>
                        {folder.name}
                      </option>
                    ))}
                  </select>

                  <div className="flex space-x-4">
                    <button
                      onClick={handleMoveToFolder}
                      disabled={!selectedFolder}
                      className={`flex-1 py-3 rounded-xl text-white transition duration-150 ${
                        selectedFolder 
                          ? 'bg-blue-500 hover:bg-blue-600' 
                          : 'bg-gray-400 cursor-not-allowed'
                      }`}
                    >
                      Move to Folder
                    </button>
                    <button
                      onClick={handleSkip}
                      className="flex-1 py-3 bg-gray-200 text-gray-700 rounded-xl hover:bg-gray-300 transition duration-150"
                    >
                      Skip
                    </button>
                  </div>
                </div>
              </div>
            )
          )}

          {error && (
            <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded-xl mt-4">
              {error}
            </div>
          )}

          <div className="mt-8 bg-blue-50 rounded-xl p-6">
            <h4 className="text-lg font-semibold text-blue-800 mb-3">Tips for importing</h4>
            <ul className="space-y-2 text-gray-600">
              <li className="flex items-center">
                <svg className="w-5 h-5 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Make sure you're using a valid SAM.gov opportunity URL
              </li>
              <li className="flex items-center">
                <svg className="w-5 h-5 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                The opportunity should be publicly accessible
              </li>
              <li className="flex items-center">
                <svg className="w-5 h-5 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Import will include all available opportunity details
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportModal; 
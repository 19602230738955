import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState(() => ({
        isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
        isLoading: true,
        user: JSON.parse(localStorage.getItem('user') || 'null')
    }));

    const checkAuth = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/check-auth`, {
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            console.log('Auth check response:', data);

            if (data.authenticated && data.user) {
                localStorage.setItem('isAuthenticated', 'true');
                localStorage.setItem('user', JSON.stringify(data.user));
                setAuthState({
                    isAuthenticated: true,
                    isLoading: false,
                    user: data.user
                });
            } else {
                localStorage.removeItem('isAuthenticated');
                localStorage.removeItem('user');
                setAuthState({
                    isAuthenticated: false,
                    isLoading: false,
                    user: null
                });
            }
        } catch (error) {
            console.error('Auth check error:', error);
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('user');
            setAuthState({
                isAuthenticated: false,
                isLoading: false,
                user: null
            });
        }
    }, []);

    useEffect(() => {
        checkAuth();
        
        const interval = setInterval(checkAuth, 5 * 60 * 1000);
        
        return () => clearInterval(interval);
    }, [checkAuth]);

    const logout = useCallback(async () => {
        try {
            await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
                method: 'POST',
                credentials: 'include',
            });
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('user');
            setAuthState({
                isAuthenticated: false,
                isLoading: false,
                user: null
            });
        }
    }, []);

    return (
        <AuthContext.Provider value={{ 
            ...authState, 
            checkAuth,
            logout
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
const API_BASE_URL = process.env.REACT_APP_API_URL || '';

// Helper function for API calls
async function fetchApi(endpoint, options = {}) {
  const response = await fetch(`${API_BASE_URL}${endpoint}`, {
    ...options,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...options.headers,
    },
  });

  if (!response.ok) {
    const error = await response.json().catch(() => ({}));
    throw new Error(error.message || 'API request failed');
  }

  return response.json();
}

// Add this new method
async function getFolderContentsAll() {
    return fetchApi('/api/folder_contents_all');
}

export const api = {
  // Opportunities
  async searchOpportunities(searchTerm, filters = {}) {
    const queryParams = new URLSearchParams({
      q: searchTerm,
      ...filters,
    });
    return fetchApi(`/api/opportunities/search?${queryParams}`);
  },

  async getOpportunity(id) {
    return fetchApi(`/api/opportunities/${id}`);
  },

  async saveNotes(opportunityId, notes) {
    return fetchApi(`/api/opportunities/${opportunityId}/notes`, {
      method: 'PUT',
      body: JSON.stringify({ notes }),
    });
  },

  // Folders
  async getFolders() {
    return fetchApi('/api/folders');
  },

  async createFolder(name) {
    return fetchApi('/api/folders', {
      method: 'POST',
      body: JSON.stringify({ name }),
    });
  },

  async deleteFolder(folderId) {
    return fetchApi(`/api/folders/${folderId}`, {
      method: 'DELETE',
    });
  },

  async assignToFolder(opportunityId, folderId) {
    return fetchApi(`/api/opportunities/${opportunityId}/folders`, {
      method: 'POST',
      body: JSON.stringify({ folderId }),
    });
  },

  async removeFromFolder(opportunityId, folderId) {
    return fetchApi(`/api/opportunities/${opportunityId}/folders/${folderId}`, {
      method: 'DELETE',
    });
  },

  // Files
  async uploadFile(opportunityId, file) {
    const formData = new FormData();
    formData.append('file', file);

    return fetch(`${API_BASE_URL}/api/opportunities/${opportunityId}/files`, {
      method: 'POST',
      body: formData,
    }).then(response => response.json());
  },

  async getFiles(opportunityId) {
    return fetchApi(`/api/opportunities/${opportunityId}/files`);
  },

  async deleteFile(fileId) {
    return fetchApi(`/api/files/${fileId}`, {
      method: 'DELETE',
    });
  },

  // AI Analysis
  async getAIAnalysis(opportunityId) {
    return fetchApi(`/api/opportunities/${opportunityId}/ai-analysis`);
  },

  async getFolder(folderId) {
    return fetchApi(`/api/folders/${folderId}`);
  },

  async getFolderOpportunities(folderId) {
    return fetchApi(`/api/folders/${folderId}/opportunities`);
  },

  getFolderContentsAll,
};

export default api;

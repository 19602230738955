import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import OpportunityCard from '../components/opportunities/OpportunityCard';
import OpportunityModal from '../components/opportunities/OpportunityModal';
import { useOpportunities } from '../hooks/useOpportunities';
import { endpoints } from '../config/api';

function FolderView() {
  const { folderId } = useParams();
  const [folder, setFolder] = useState(null);
  const [folders, setFolders] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const { loading: oppsLoading, error } = useOpportunities({}, folderId);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [pinnedOpportunities, setPinnedOpportunities] = useState(new Set());
  const [sortOrder, setSortOrder] = useState('closest');

  useEffect(() => {
    const fetchFolderDetails = async () => {
      setLoading(true);
      try {
        const [folderResponse, allFoldersResponse] = await Promise.all([
          fetch(`${endpoints.folders.get}/${folderId}?sortOrder=${sortOrder}`, {
            credentials: 'include',
            headers: { 'Accept': 'application/json' }
          }),
          fetch(endpoints.folders.getAll, {
            credentials: 'include',
            headers: { 'Accept': 'application/json' }
          })
        ]);

        if (folderResponse.ok) {
          const folderData = await folderResponse.json();
          setFolder(folderData);
        }

        if (allFoldersResponse.ok) {
          const allFolders = await allFoldersResponse.json();
          setFolders(allFolders);
        }

        const oppsResponse = await fetch(`/api/folder_contents/${folderId}?sortOrder=${sortOrder}`, {
          credentials: 'include',
          headers: { 'Accept': 'application/json' }
        });
        
        if (oppsResponse.ok) {
          const oppsData = await oppsResponse.json();
          setOpportunities(oppsData);
        }
        
      } catch (error) {
        console.error('Error fetching folder details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFolderDetails();
  }, [folderId, refreshTrigger, sortOrder]);

  useEffect(() => {
    const loadPinnedOpportunities = () => {
      const stored = localStorage.getItem(`pinned-opportunities-${folderId}`);
      if (stored) {
        setPinnedOpportunities(new Set(JSON.parse(stored)));
      }
    };
    loadPinnedOpportunities();
  }, [folderId]);

  const handleRemoveFromFolder = async (opportunityId) => {
    try {
      const response = await fetch('/api/remove_from_folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          opportunityId,
          folderId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to remove from folder');
      }

      // Trigger a refresh of the folder contents
      setRefreshTrigger(prev => prev + 1);
      
      // Optionally show a success message
      // You could add a toast notification here
    } catch (error) {
      console.error('Error removing from folder:', error);
      // Optionally show an error message
    }
  };

  const handleTogglePin = (opportunityId) => {
    setPinnedOpportunities(prevPinned => {
      const newPinned = new Set(prevPinned);
      if (newPinned.has(opportunityId)) {
        newPinned.delete(opportunityId);
      } else {
        newPinned.add(opportunityId);
      }
      
      // Save to localStorage
      localStorage.setItem(
        `pinned-opportunities-${folderId}`,
        JSON.stringify([...newPinned])
      );
      
      return newPinned;
    });
  };

  const sortedOpportunities = React.useMemo(() => {
    if (!Array.isArray(opportunities)) return [];
    
    return [...opportunities].sort((a, b) => {
      const aIsPinned = pinnedOpportunities.has(a.id);
      const bIsPinned = pinnedOpportunities.has(b.id);
      
      if (aIsPinned && !bIsPinned) return -1;
      if (!aIsPinned && bIsPinned) return 1;
      return 0;
    });
  }, [opportunities, pinnedOpportunities]);

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleOpportunityUpdate = (updatedOpportunity) => {
    setOpportunities(prevOpportunities => 
      prevOpportunities.map(opp => 
        opp.id === updatedOpportunity.id ? updatedOpportunity : opp
      )
    );
    setSelectedOpportunity(updatedOpportunity);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 via-white to-blue-50">
      <div className="container mx-auto py-12">
        {/* Header with Back Button */}
        <div className="max-w-7xl mx-auto mb-8">
          <Link
            to="/saved"
            className="inline-flex items-center text-blue-600 hover:text-blue-700 mb-6"
          >
            ← Back to Folders
          </Link>
          <h1 className="text-3xl font-bold text-gray-900">
            {loading ? 'Loading...' : folder?.name || 'Unnamed Folder'}
          </h1>
          <p className="text-gray-600 mt-2">
            {Array.isArray(opportunities) ? opportunities.length : 0} opportunities
          </p>
        </div>

        {/* Add Sort Filter */}
        <div className="max-w-7xl mx-auto mb-6">
          <div className="flex items-center space-x-4">
            <label htmlFor="sortOrder" className="text-gray-700">Sort by deadline:</label>
            <select
              id="sortOrder"
              value={sortOrder}
              onChange={handleSortChange}
              className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="closest">Closest First</option>
              <option value="furthest">Furthest First</option>
            </select>
          </div>
        </div>

        {/* Loading State */}
        {(loading || oppsLoading) && (
          <div className="flex justify-center items-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        )}

        {/* Error State */}
        {error && (
          <div className="text-center py-12 text-red-600">
            Error loading opportunities: {error}
          </div>
        )}

        {/* Opportunities Grid */}
        {!loading && !error && Array.isArray(opportunities) && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {sortedOpportunities.map(opportunity => (
              <OpportunityCard
                key={opportunity.id}
                opportunity={opportunity}
                onClick={setSelectedOpportunity}
                folders={folders.filter(f => f.id !== parseInt(folderId))}
                inFolder={true}
                onRemoveFromFolder={handleRemoveFromFolder}
                isPinned={pinnedOpportunities.has(opportunity.id)}
                onTogglePin={handleTogglePin}
              />
            ))}
          </div>
        )}

        {/* Empty State */}
        {!loading && !error && (!Array.isArray(opportunities) || opportunities.length === 0) && (
          <div className="text-center py-12">
            <p className="text-gray-500">No opportunities in this folder yet.</p>
          </div>
        )}

        {/* Modal */}
        {selectedOpportunity && (
          <OpportunityModal
            show={!!selectedOpportunity}
            opportunity={selectedOpportunity}
            onClose={() => setSelectedOpportunity(null)}
            onUpdate={handleOpportunityUpdate}
          />
        )}
      </div>
    </div>
  );
}

export default FolderView; 
import React, { useState, useEffect } from 'react';
import CreateFolderModal from '../components/folders/CreateFolderModal';
import { Link } from 'react-router-dom';
import { endpoints } from '../config/api';

function SavedOpportunities() {
  const [folders, setFolders] = useState([]);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [folderContents, setFolderContents] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    fetchFolderData();
  }, []);

  const fetchFolderData = async () => {
    setLoading(true);
    try {
      // Fetch folders and their contents in parallel
      const [foldersResponse, contentsResponse] = await Promise.all([
        fetch(endpoints.folders.getAll, {
          credentials: 'include',
          headers: { 'Accept': 'application/json' }
        }),
        fetch(endpoints.folders.getAllContents, {
          credentials: 'include',
          headers: { 'Accept': 'application/json' }
        })
      ]);

      if (!foldersResponse.ok || !contentsResponse.ok) {
        throw new Error('Failed to fetch folder data');
      }

      const foldersData = await foldersResponse.json();
      const contentsData = await contentsResponse.json();

      setFolders(foldersData);
      setFolderContents(contentsData);
    } catch (error) {
      console.error('Error fetching folder data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      const response = await fetch(`/api/delete_folder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ id: folderId })
      });

      if (!response.ok) throw new Error('Failed to delete folder');

      // Remove folder from state
      setFolders(folders.filter(folder => folder.id !== folderId));
    } catch (error) {
      console.error('Error deleting folder:', error);
    }
  };

  // Use folderContents when rendering instead of making individual requests
  const getFolderContent = (folderId) => {
    return folderContents[folderId] || [];
  };

  const moveFolder = async (folderId, direction) => {
    const currentIndex = folders.findIndex(f => f.id === folderId);
    if (
      (direction === 'up' && currentIndex === 0) || 
      (direction === 'down' && currentIndex === folders.length - 1)
    ) {
      return; // Can't move further up/down
    }

    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
    const newFolders = [...folders];
    const [removed] = newFolders.splice(currentIndex, 1);
    newFolders.splice(newIndex, 0, removed);

    // Update local state immediately
    setFolders(newFolders);

    // Prepare folder orders for backend
    const folderOrders = newFolders.map((folder, index) => ({
      id: folder.id,
      order: index
    }));

    try {
      const response = await fetch('/api/update_folder_order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ folderOrders })
      });

      if (!response.ok) {
        throw new Error('Failed to update folder order');
      }
    } catch (error) {
      console.error('Error updating folder order:', error);
      fetchFolderData(); // Revert on error
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 via-white to-blue-50">
      <div className="container mx-auto py-12">
        {/* Header with Edit Toggle */}
        <header className="text-center mb-12">
          <div className="flex justify-between items-center max-w-4xl mx-auto mb-6">
            <h1 className="text-4xl font-bold text-blue-800">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">
                Saved Opportunities
              </span>
            </h1>
            <button
              onClick={() => setIsEditMode(!isEditMode)}
              className={`px-4 py-2 rounded-lg transition-all duration-300 ${
                isEditMode 
                  ? 'bg-green-500 hover:bg-green-600 text-white'
                  : 'bg-blue-500 hover:bg-blue-600 text-white'
              }`}
            >
              {isEditMode ? (
                <span className="flex items-center">
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  Done Editing
                </span>
              ) : (
                <span className="flex items-center">
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                  </svg>
                  Reorder Folders
                </span>
              )}
            </button>
          </div>
          <p className="text-xl text-gray-600">
            {isEditMode ? 'Drag folders to reorder them' : 'Select a folder to view its opportunities'}
          </p>
        </header>

        <div className="max-w-4xl mx-auto">
          {isEditMode ? (
            <div className="space-y-4">
              {folders.map((folder, index) => (
                <div
                  key={folder.id}
                  className="bg-white rounded-xl p-6 shadow-md border border-gray-100 relative group"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <h3 className="text-xl font-semibold text-gray-800">{folder.name}</h3>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span className="text-gray-500 mr-4">
                        {getFolderContent(folder.id).length || 0} opportunities
                      </span>
                      {/* Move Up Button */}
                      <button
                        onClick={() => moveFolder(folder.id, 'up')}
                        disabled={index === 0}
                        className={`p-2 rounded-lg hover:bg-gray-100 transition-colors ${
                          index === 0 ? 'text-gray-300' : 'text-gray-600'
                        }`}
                        title="Move Up"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                        </svg>
                      </button>
                      {/* Move Down Button */}
                      <button
                        onClick={() => moveFolder(folder.id, 'down')}
                        disabled={index === folders.length - 1}
                        className={`p-2 rounded-lg hover:bg-gray-100 transition-colors ${
                          index === folders.length - 1 ? 'text-gray-300' : 'text-gray-600'
                        }`}
                        title="Move Down"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            // Regular folder grid view
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {folders.map((folder) => (
                <Link
                  key={folder.id}
                  to={`/folders/${folder.id}`}
                  className="block bg-white rounded-xl p-6 shadow-md hover:shadow-xl transition-all duration-300 border border-gray-100 group relative"
                >
                  {/* Existing folder content */}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (window.confirm('Are you sure you want to delete this folder?')) {
                        handleDeleteFolder(folder.id);
                      }
                    }}
                    className="absolute top-4 right-4 text-gray-400 hover:text-red-500 transition-colors"
                    aria-label="Delete folder"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                  <div className="flex items-center space-x-3 mb-4">
                    <svg className="w-8 h-8 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
                    </svg>
                    <h3 className="text-xl font-semibold text-gray-800">{folder.name}</h3>
                  </div>
                  <p className="text-gray-600">
                    {getFolderContent(folder.id).length || 0} opportunities
                  </p>
                  <div className="mt-4 text-blue-500 group-hover:text-blue-600">
                    View Contents →
                  </div>
                </Link>
              ))}
            </div>
          )}

          {/* Create Folder Button */}
          {!isEditMode && (
            <button
              onClick={() => setShowFolderModal(true)}
              className="w-full mt-6 bg-gray-50 rounded-xl p-6 border-2 border-dashed border-gray-200 hover:border-blue-300 hover:bg-gray-100 transition-all duration-300 flex flex-col items-center justify-center text-gray-500 hover:text-blue-500"
            >
              <svg className="w-12 h-12 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              Create New Folder
            </button>
          )}
        </div>
      </div>

      {/* Modals */}
      <CreateFolderModal
        show={showFolderModal}
        onClose={() => setShowFolderModal(false)}
        onSave={fetchFolderData}
      />

      {/* Loading Spinner */}
      {loading && (
        <div className="fixed inset-0 bg-white bg-opacity-75 flex justify-center items-center z-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-600"></div>
        </div>
      )}
    </div>
  );
}

export default SavedOpportunities;
import React, { useState, useEffect, useCallback } from 'react';
import SearchBar from '../components/filters/SearchBar';
import AdvancedFilters from '../components/filters/AdvancedFilters';
import ImportModal from '../components/import/ImportModal';
import ResultsGrid from '../components/opportunities/ResultsGrid';
import Spinner from '../components/common/Spinner';
import { endpoints } from '../config/api';
import { useNavigate } from 'react-router-dom';
import OpportunityModal from '../components/opportunities/OpportunityModal';

function Home() {
  const [searchTerm, setSearchTerm] = useState('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [opportunities, setOpportunities] = useState([]);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    setAsideTypes: [],
    sortOrder: 'newest',
    currentDateOnly: false,
    excludeDLA: false
  });
  const [folders, setFolders] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const navigate = useNavigate();

  const checkAuth = useCallback(async () => {
    try {
      const response = await fetch(endpoints.auth.check, {
        credentials: 'include'
      });
      if (!response.ok) {
        localStorage.removeItem('token');
        navigate('/login', { replace: true });
      }
    } catch (error) {
      console.error('Auth check error:', error);
      localStorage.removeItem('token');
      navigate('/login', { replace: true });
    }
  }, [navigate]);

  const fetchInitialData = useCallback(async () => {
    try {
      const [opportunitiesResponse, foldersResponse] = await Promise.all([
        fetch(`${endpoints.opportunities.get}?page=${currentPage}`, {
          credentials: 'include',
          headers: { 'Accept': 'application/json' }
        }),
        fetch(endpoints.folders.getAll, { credentials: 'include' })
      ]);

      const opportunitiesData = await opportunitiesResponse.json();
      const foldersData = await foldersResponse.json();

      setOpportunities(opportunitiesData.opportunities || []);
      setTotalPages(opportunitiesData.pages || 1);
      setFolders(foldersData);
    } catch (error) {
      console.error('Error fetching initial data:', error);
    } finally {
      setInitialLoading(false);
    }
  }, [currentPage]);

  useEffect(() => {
    const loadSequence = async () => {
      await checkAuth();
      await fetchInitialData();
      setLoading(false);
    };

    loadSequence();
  }, [checkAuth, fetchInitialData]);

  const handleSearch = async (searchParams) => {
    setSearchTerm(searchParams);
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        query: searchParams || '',
        page: currentPage,
        sortOrder: filters.sortOrder,
        currentDateOnly: filters.currentDateOnly,
        excludeDLA: filters.excludeDLA,
      });

      filters.setAsideTypes.forEach(type => {
        queryParams.append('setAsideTypes[]', type);
      });

      const response = await fetch(`${endpoints.opportunities.search}?${queryParams}`, {
        credentials: 'include',
      });
      
      if (!response.ok) throw new Error('Search failed');
      
      const data = await response.json();
      setOpportunities(data.opportunities || []);
      setTotalPages(data.pages || 1);
    } catch (error) {
      console.error('Search failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleApplyFilters = useCallback(async (newFilters) => {
    setLoading(true);
    setFilters(newFilters);

    try {
      const queryParams = new URLSearchParams({
        page: currentPage.toString(),
        sortOrder: newFilters.sortOrder,
        currentDateOnly: newFilters.currentDateOnly.toString(),
        excludeDLA: newFilters.excludeDLA.toString(),
      });

      newFilters.setAsideTypes.forEach(type => {
        queryParams.append('setAsideTypes[]', type);
      });

      if (searchTerm) {
        queryParams.append('query', searchTerm);
      }

      const response = await fetch(`${endpoints.opportunities.search}?${queryParams}`, {
        credentials: 'include',
      });

      if (!response.ok) throw new Error('Search failed');

      const data = await response.json();
      setOpportunities(data.opportunities || []);
      setTotalPages(data.pages || 1);
    } catch (error) {
      console.error('Filter application failed:', error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchTerm]);

  const handleOpportunityClick = (opportunity) => {
    setSelectedOpportunity(opportunity);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleOpportunityUpdate = (updatedOpportunity) => {
    setOpportunities(prevOpportunities => 
      prevOpportunities.map(opp => 
        opp.id === updatedOpportunity.id ? updatedOpportunity : opp
      )
    );
    setSelectedOpportunity(updatedOpportunity);
  };

  return (
    <div className="container mx-auto py-12 relative overflow-hidden">
      {/* Primary blobs */}
      <div className="absolute top-0 -left-4 w-96 h-96 bg-blue-200/60 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
      <div className="absolute top-20 right-0 w-72 h-72 bg-purple-200/60 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
      <div className="absolute top-40 left-20 w-72 h-72 bg-indigo-200/60 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
      
      {/* Secondary blobs */}
      <div className="absolute -bottom-8 right-40 w-80 h-80 bg-blue-100/60 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-3000"></div>
      <div className="absolute bottom-40 -left-10 w-80 h-80 bg-purple-100/60 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-5000"></div>
      <div className="absolute top-1/2 left-1/2 w-72 h-72 bg-indigo-100/60 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-1000"></div>

      <header className="text-center mb-12">
        <h1 className="text-4xl font-bold text-blue-800 mb-2">Procure Pro</h1>
        <p className="text-xl text-gray-600">
          Discover and bid on government opportunities
        </p>
      </header>

      <div className="mx-auto mb-12">
        <div className="relative max-w-4xl mx-auto">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 transform -skew-y-2 rounded-3xl shadow-lg"></div>
          <div className="relative bg-white bg-opacity-90 backdrop-filter backdrop-blur-sm rounded-3xl p-8 shadow-sm">
            <SearchBar onSearch={handleSearch} />
            <div className="flex justify-between items-center mt-4">
              <button
                onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                className="text-gray-600 hover:text-blue-500 transition duration-150"
              >
                Advanced Filters
              </button>
              <button
                onClick={() => setShowImportModal(true)}
                className="text-gray-600 hover:text-blue-500 transition duration-150"
              >
                Import
              </button>
            </div>
          </div>
        </div>
      </div>

      <AdvancedFilters 
        show={showAdvancedFilters} 
        onApplyFilters={handleApplyFilters}
      />

      {loading || initialLoading ? (
        <Spinner />
      ) : (
        <ResultsGrid 
          opportunities={opportunities}
          onOpportunityClick={handleOpportunityClick}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          folders={folders}
        />
      )}

      <OpportunityModal
        show={selectedOpportunity !== null}
        opportunity={selectedOpportunity}
        onClose={() => setSelectedOpportunity(null)}
        onUpdate={handleOpportunityUpdate}
      />

      <ImportModal 
        isOpen={showImportModal} 
        onClose={() => setShowImportModal(false)} 
        folders={folders}
      />
    </div>
  );
}

export default Home;